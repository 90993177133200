import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Navigation from "./routes/Navigation";
import Footer from "./components/Footer";
import ChatbotWidget from './components/chatbot/ChatbotWidget';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import './global.css';
import { isAuthenticated2 } from "./services/account/account.service";
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const loginURL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:3000"
    : "https://claimscortex.com";

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        console.log("authToken", authToken);
        const urlParams = new URLSearchParams(window.location.search);
        const authTokenFromURL = urlParams.get('token');

        // Prioritize URL token if present
        const tokenToValidate = authTokenFromURL || authToken;

        if (tokenToValidate) {
          const headers = {
            "Content-Type": "application/json"
          };

          const response = await isAuthenticated2({ authToken: tokenToValidate }, headers);
          console.log("response", response);
          // Assume response indicates successful authentication
          setIsAuthenticated(!!response);
          localStorage.setItem('accountId',response.data.accountId);
          console.log("response.data.accountId",response.data.accountId);
          // Update localStorage with validated token if URL token was used
          if (authTokenFromURL) {
            localStorage.setItem('authToken', authTokenFromURL);
            localStorage.setItem('accountId',response.data.accountId);
            console.log("response.data.accountId",response.data.accountId);
            navigate("/");

          }
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Authentication check failed", error);
        setIsAuthenticated(false);
      } finally {
        setAuthLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  const showStepper = location.pathname !== "/login";
  const loginText = "Logout" ;
  const editText = isAuthenticated ? "Edit Account" : "Create Account";
  const accountPath = isAuthenticated ? "/editAccount" : "/createAccount";

  const handleEditOrCreate = () => {
    navigate(isAuthenticated ? "/editaccount" : "/createaccount");
  };

  const handleLogout = () => {
    if (isAuthenticated) {
      // Logout logic
      localStorage.removeItem('authToken');
      localStorage.removeItem('accountId');
      setIsAuthenticated(false);
      window.location.href = loginURL;
    } else {
      window.location.href = loginURL;
    }
  };

  if (authLoading) {
    return <div>Loading...</div>; // Optional: add a loading state
  }

  return (
    <>
      <Navbar 
        style={{ 
          background: "linear-gradient(90deg, rgb(29 31 59) 32%, rgba(82, 52, 226, 1) 100%)" 
        }} 
        expand="lg" 
        className="position-relative"
      >
        <Container fluid>
          <Navbar.Brand as={Link} to="/" style={{ color: "White" }} className="navbar-center">
            Claims Cortex
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="ml-auto d-flex align-items-center">
              {isAuthenticated && (
                <>
                  <Nav.Link as={Link} to="/" className="text-white">
                    Negotiator
                  </Nav.Link>
                  <Nav.Link as={Link} to="/estimateStepper" className="text-white">
                    XActiwriter
                  </Nav.Link>
                  <Nav.Link as={Link} to="/billing" className="text-white">
                    Billing
                  </Nav.Link>
                  <Nav.Link onClick={handleEditOrCreate} className="text-white">
                    Account
                  </Nav.Link>
                </>
              )}
              {isAuthenticated && (
              <Button variant="outline-light" onClick={handleLogout}>
                {loginText}
              </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navigation />
      {showStepper && isAuthenticated && (
        <ChatbotWidget />
      )}
      <Footer />
    </>
  );
};

export default App;